import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Icon from "../custom-widgets/icon";

import "./quick-links.bootstrap.scss";
import { useLanguageContext } from "../../contexts/language-context";
import useMediaQuery from "../../hooks/use-media-query";

const QuickLinks = ({
  id = "home-quick-links",
  // Default Data - homepage
  quickLinksData = [
    {
      id: "quick-links-open-bank-account-online",
      iconSource: "../../images/icons/open-an-account-icon-active-70.svg",
      hoverIconSource: "../../images/icons/open-an-account-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/open-an-account-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/open-an-account-icon-hover-44.svg",
      altText: "Open an Account.",
      text: "Open an Account",
      url: "/open-bank-account-online"
    },
    {
      id: "quick-links-home-loans",
      iconSource: "../../images/icons/home-loans-icon-active-70.svg",
      hoverIconSource: "../../images/icons/home-loans-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/home-loans-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/home-loans-icon-hover-44.svg",
      altText: "Home Loans.",
      text: "Home Loans",
      url: "/personal-banking/home-loans/home-mortgage"
    },
    {
      id: "quick-links-credit-cards",
      iconSource: "../../images/icons/credit-cards-icon-active-70.svg",
      hoverIconSource: "../../images/icons/credit-cards-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/credit-cards-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/credit-cards-icon-hover-44.svg",
      altText: "Credit Cards.",
      text: "Credit Cards",
      url: "/personal-banking/credit-cards"
    },
    {
      id: "quick-links-branch-locations",
      iconSource: "../../images/icons/locations-icon-active-70.svg",
      hoverIconSource: "../../images/icons/locations-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/locations-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/locations-icon-hover-44.svg",
      altText: "Branch Locations.",
      text: "Locations",
      url: "/locations"
    },
    {
      id: "quick-links-checking-account",
      iconSource: "../../images/icons/checking-accounts-icon-active-70.svg",
      hoverIconSource: "../../images/icons/checking-accounts-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/checking-accounts-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/checking-accounts-icon-hover-44.svg",
      altText: "Checking Accounts.",
      text: "Checking Accounts",
      url: "/personal-banking/checking-account"
    },
    {
      id: "quick-links-savings-account",
      iconSource: "../../images/icons/savings-accounts-icon-active-70.svg",
      hoverIconSource: "../../images/icons/savings-accounts-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/savings-accounts-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/savings-accounts-icon-hover-44.svg",
      altText: "Savings Accounts.",
      text: "Savings Accounts",
      url: "/personal-banking/savings-account"
    },
    {
      id: "quick-links-small-business",
      iconSource: "../../images/icons/business-icon-active-70.svg",
      hoverIconSource: "../../images/icons/business-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/business-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/business-icon-hover-44.svg",
      altText: "Small Business Banking.",
      text: "Business",
      url: "/business-banking/small-business"
    }
  ],
  quickLinksSpanishData = [
    {
      id: "quick-links-es-open-bank-account-online",
      iconSource: "../../images/icons/open-an-account-icon-active-70.svg",
      hoverIconSource: "../../images/icons/open-an-account-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/open-an-account-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/open-an-account-icon-hover-44.svg",
      altText: "Abrir Cuenta En Línea",
      esText: "Abrir Cuenta En Línea",
      esUrl: "/es/abrir-cuenta-bancaria-en-linea"
    },
    {
      id: "quick-links-es-home-loans",
      iconSource: "../../images/icons/home-loans-icon-active-70.svg",
      hoverIconSource: "../../images/icons/home-loans-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/home-loans-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/home-loans-icon-hover-44.svg",
      altText: "Préstamos Hipotecarios",
      esText: "Préstamos Hipotecarios",
      esUrl: "/es/banca-personal/prestamos-hipotecarios"
    },
    {
      id: "quick-links-es-credit-cards",
      iconSource: "../../images/icons/credit-cards-icon-active-70.svg",
      hoverIconSource: "../../images/icons/credit-cards-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/credit-cards-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/credit-cards-icon-hover-44.svg",
      altText: "Tarjetas de Crédito",
      esText: "Tarjetas de Crédito",
      esUrl: "/es/banca-personal/tarjetas-de-credito"
    },
    {
      id: "quick-links-es-branch-locations",
      iconSource: "../../images/icons/locations-icon-active-70.svg",
      hoverIconSource: "../../images/icons/locations-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/locations-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/locations-icon-hover-44.svg",
      altText: "Ubicación de Sucursales",
      esText: "Ubicación de Sucursales",
      esUrl: "/es/sucursales"
    },
    {
      id: "quick-links-es-checking-account",
      iconSource: "../../images/icons/checking-accounts-icon-active-70.svg",
      hoverIconSource: "../../images/icons/checking-accounts-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/checking-accounts-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/checking-accounts-icon-hover-44.svg",
      altText: "Cuenta de Cheques",
      esText: "Cuenta de Cheques",
      esUrl: "/es/banca-personal/cuenta-de-cheques"
    },
    {
      id: "quick-links-es-savings-account",
      iconSource: "../../images/icons/savings-accounts-icon-active-70.svg",
      hoverIconSource: "../../images/icons/savings-accounts-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/savings-accounts-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/savings-accounts-icon-hover-44.svg",
      altText: "Cuenta de Ahorro",
      esText: "Cuenta de Ahorro",
      esUrl: "/es/banca-personal/cuentas-de-ahorro"
    },
    {
      id: "quick-links-es-faq",
      iconSource: "../../images/icons/faq-icon-active-70.svg",
      hoverIconSource: "../../images/icons/faq-icon-hover-70.svg",
      iconSourceXs: "../../images/icons/faq-icon-active-44.svg",
      hoverIconSourceXs: "../../images/icons/faq-icon-hover-44.svg",
      altText: "Preguntas Frecuentes",
      esText: "Preguntas Frecuentes",
      esUrl: "/es/preguntas-frecuentes"
    }
  ],
  responsive = [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: quickLinksData.length,
        slidesToScroll: quickLinksData.length
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: quickLinksData.length,
        slidesToScroll: quickLinksData.length
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: quickLinksData.length,
        slidesToScroll: quickLinksData.length
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: quickLinksData.length - 2,
        slidesToScroll: quickLinksData.length - 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: quickLinksData.length - 3,
        slidesToScroll: quickLinksData.length - 3
      }
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: quickLinksData.length - 4,
        slidesToScroll: quickLinksData.length - 4
      }
    }
  ],
  className = ""
}) => {
  const minMedium = `only screen and (min-width: 768px)`;

  const matchMedium = useMediaQuery(minMedium);
  const isSpanish = useLanguageContext();
  const hasSpanishData = quickLinksSpanishData && quickLinksSpanishData.length > 0;

  const initialImageState = new Array(quickLinksData.length).fill(true);

  const [activeImage, setActiveImage] = useState(initialImageState);

  function updateImageState(index, value) {
    let newState = [...initialImageState];
    newState[index] = value;
    setActiveImage(newState);
  }

  const SamplePrevArrow = (props) => {
    return (
      <button tabIndex={0} className={`${props.className}  ${props.class}`} style={props.style} onClick={props.onClick}>
        <Icon class={`${props.class}-icon`} name="chevron-left" lib="fal" />
      </button>
    );
  };

  const SampleNextArrow = (props) => {
    return (
      <button tabIndex={0} className={`${props.className}  ${props.class}`} style={props.style} onClick={props.onClick}>
        <Icon class={`${props.class}-icon`} name="chevron-right" lib="fal" />
      </button>
    );
  };

  const sliderRef = useRef(null);

  const updateAriaHidden = () => {
    const slider = sliderRef.current;

    if (slider && slider.innerSlider) {
      const slides = slider.innerSlider.list.querySelectorAll(".slick-slide");

      slides.forEach((slide) => {
        slide.removeAttribute("aria-hidden");
      });
    }
  };

  useEffect(() => {
    updateAriaHidden();
  }, []);

  const responsiveSpanish = [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: quickLinksSpanishData.length,
        slidesToScroll: quickLinksSpanishData.length
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: quickLinksSpanishData.length,
        slidesToScroll: quickLinksSpanishData.length
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: quickLinksSpanishData.length,
        slidesToScroll: quickLinksSpanishData.length
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: quickLinksSpanishData.length - 2,
        slidesToScroll: quickLinksSpanishData.length - 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: quickLinksSpanishData.length - 4,
        slidesToScroll: quickLinksSpanishData.length - 4
      }
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: quickLinksSpanishData.length - 4,
        slidesToScroll: quickLinksSpanishData.length - 4
      }
    }
  ];

  // Override responsive as spanish titles are bigger
  const responsiveData = isSpanish && hasSpanishData ? responsiveSpanish : responsive;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: quickLinksData.length,
    slidesToScroll: quickLinksData.length,
    easing: "linear",
    draggable: true,
    nextArrow: <SampleNextArrow class="custom-next-arrow" />,
    prevArrow: <SamplePrevArrow class="custom-prev-arrow" />,

    accessibility: true,
    responsive: responsiveData,
    afterChange: updateAriaHidden,
    onReInit: updateAriaHidden
  };

  return (
    <div id={id} className={`quick-links-component ${className}`}>
      <div className="container">
        <div className="row justify-content-center">
          <Slider ref={sliderRef} {...settings}>
            {isSpanish && hasSpanishData
              ? quickLinksSpanishData.map((bbCard, index) => (
                  <div key={bbCard.id}>
                    {matchMedium ? (
                      <div className="d-block m-auto">
                        <div
                          className="col text-center p-0 quick-links-container"
                          id={bbCard.id + "-container"}
                          key={index}
                          onMouseEnter={() => updateImageState(index, false)}
                          onMouseLeave={() => updateImageState(index, true)}
                        >
                          <div className="slide-outline">
                            <Link to={bbCard.esUrl} id={bbCard.id} className="stretched-link">
                              <span className="sr-only">{bbCard.esText}</span>
                            </Link>
                            <div id={bbCard.id + "-icon"}>
                              <img
                                alt=""
                                className={`mx-auto ${activeImage[index] === true ? "d-flex" : "d-none"}`}
                                src={bbCard.iconSource}
                                style={{ width: "70px", height: "70px" }}
                              />
                              <img
                                alt=""
                                className={`mx-auto ${activeImage[index] === true ? "d-none" : "d-flex"}`}
                                src={bbCard.hoverIconSource}
                                style={{ width: "70px", height: "70px" }}
                              />
                            </div>
                            <div
                              className="text-secondary text-sm font-weight-semi-bold quick-links-text"
                              id={bbCard.id + "-text"}
                            >
                              {bbCard.esText}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-block">
                        <div
                          className="col text-center p-0 quick-links-container"
                          id={bbCard.id + "-container"}
                          key={index}
                        >
                          <div className="slide-outline">
                            <Link to={bbCard.esUrl} id={bbCard.id} className="stretched-link">
                              <span className="sr-only">{bbCard.esText}</span>
                            </Link>
                            <div id={bbCard.id + "-icon"}>
                              <img
                                alt=""
                                className="mx-auto"
                                src={bbCard.iconSourceXs}
                                style={{ width: "44px", height: "44px" }}
                              />
                            </div>
                            <div
                              className="text-secondary text-sm font-weight-semi-bold quick-links-text"
                              id={bbCard.id + "-text"}
                            >
                              {bbCard.esText}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              : quickLinksData.map((bbCard, index) => (
                  <div key={bbCard.id}>
                    {matchMedium ? (
                      <div className="d-block">
                        <div
                          className="col text-center p-0 quick-links-container"
                          id={bbCard.id + "-container"}
                          key={index}
                          onMouseEnter={() => updateImageState(index, false)}
                          onMouseLeave={() => updateImageState(index, true)}
                        >
                          <div className="slide-outline">
                            <Link to={bbCard.url} id={bbCard.id} className="stretched-link">
                              <span className="sr-only">{bbCard.text}</span>
                            </Link>
                            <div id={bbCard.id + "-icon"}>
                              <img
                                alt=""
                                className={`mx-auto ${activeImage[index] === true ? "d-flex" : "d-none"}`}
                                src={bbCard.iconSource}
                                style={{ width: "70px", height: "70px" }}
                              />
                              <img
                                alt=""
                                className={`mx-auto ${activeImage[index] === true ? "d-none" : "d-flex"}`}
                                src={bbCard.hoverIconSource}
                                style={{ width: "70px", height: "70px" }}
                              />
                            </div>
                            <div
                              className="text-secondary text-sm font-weight-semi-bold quick-links-text"
                              id={bbCard.id + "-text"}
                            >
                              {bbCard.text}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-block">
                        <div
                          className="col text-center p-0 quick-links-container"
                          id={bbCard.id + "-container"}
                          key={index}
                        >
                          <div className="slide-outline">
                            <Link to={bbCard.url} id={bbCard.id} className="stretched-link">
                              <span className="sr-only">{bbCard.text}</span>
                            </Link>
                            <div id={bbCard.id + "-icon"}>
                              <img
                                alt=""
                                className="mx-auto"
                                src={bbCard.iconSourceXs}
                                style={{ width: "44px", height: "44px" }}
                              />
                            </div>
                            <div
                              className="text-secondary text-sm font-weight-semi-bold quick-links-text"
                              id={bbCard.id + "-text"}
                            >
                              {bbCard.text}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
